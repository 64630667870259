<template>
  <!-- 剧集抽屉 -->
  <a-drawer
    width="700"
    :title="title"
    :closable="false"
    :visible="visible"
    @close="onClose"
    :footer-style="{ textAlign: 'right' }"
  >
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 16 }"
    >
      <!-- 模块开关 -->
      <a-form-item
        label="模块开关"
        name="ison"
      >
        <a-switch v-model:checked="formState.ison" />
      </a-form-item>
    </a-form>
    <!-- 尾部 -->
    <template #footer>
      <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
      <a-button type="primary" @click="touchSubmit">确定</a-button>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { ref, reactive, nextTick } from 'vue'
import { message } from 'ant-design-vue'
import Loading from '@/components/Loading'
import Pub from '@/utils/public'
import { getLeadConfig, leadConfigSave } from '@/api/operate'
// 引入 dayjs 及 扩展函数
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
dayjs.extend(isBetween)

const emit = defineEmits('success')
// Form实例
const RefForm = ref(null)
// 抽屉展示状态
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 平台ID
let platformID  = ref(undefined)
// 详情ID
let id = ref(undefined)
// 标题
let title = ref(undefined)
// 信息
let formState = reactive({
  // 模块开关
  ison: false
})

// 打开抽屉
function showDrawer (pid, did, text) {
  visible.value = true
  title.value = text
  id.value = did
  platformID.value = pid
  // 获取详情
  getDetail()
}

// 获取详情
function getDetail () {
  isLoading.value = true
  getLeadConfig({
    platform_id: platformID.value,
    type: id.value
  }).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      // 设置表单显示
      nextTick(() => {
        formState.ison = data.ison ? true : false
      })
    } else {
      message.error(res.message || msg)
    }
  })
}

// 关闭抽屉
function onClose () {
  if (!isLoading.value) {
    RefForm.value.resetFields()
    visible.value = false
  }
}

// 准备上传（图片）
function beforeUploadPro (file) {
  if (!file.type.includes('image')) {
    message.error('请上传图片类型的文件')
    return false
  }
  return true
}

// 表单校验
function validator (rule, value) {
  if (formState.ison) {
    // 广告参数
    if (rule.field === 'adid') {
      if (!value) {
        return Promise.reject('请输入')
      }
      return Promise.resolve()
    }
    // 一次性解锁
    if (rule.field === 'num') {
      if (!value) {
        return Promise.reject('请输入')
      }
      else if (!Pub.REG_IS_INTEGER(value)) {
        return Promise.reject('必须为正整数')
      }
      return Promise.resolve()
    }
  } else {
    return Promise.resolve()
  }
}

// 提交
function touchSubmit () {
  RefForm.value.validate().then((() => {
    // 检查上传
    const imgs = formState.avatar || []
    const isUploadSuccess = imgs.every(item => {
      return item.status === undefined || item.status === 'done'
    })
    if (!isUploadSuccess) {
      message.error('正在上传中...')
      return
    }
    isLoading.value = true
    // 准备数据
    const params = {
      platform_id: platformID.value,
      type: id.value,
      ...formState,
      ison: formState.ison ? 1 : 0
    }
    leadConfigSave(params).then(res => {
      isLoading.value = false
      const { code, msg, data } = res
      if (code === 0) {
        message.success('操作成功')
        onClose()
        emit('success')
      } else {
        message.error(res.message || msg)
      }
    })
  }))
}

// 暴露出去
defineExpose({
  showDrawer
})

</script>

<style lang="less" scoped>
.a-link {
  margin-left: 8px;
}
.form-item-clear {
  margin-bottom: 0;
}
.form-upload-hint {
  padding-top: 6px;
  color: #8D8D8D;
}
.form-upload-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
}
// .form-upload-item {
//   margin-top: 10px;
//   margin-left: 10px;
//   position: relative;
//   width: 118px;
//   height: 148px;
//   border-radius: 4px;
//   overflow: hidden;
//   img {
//     display: inline-block;
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//   }
//   &.horizontal {
//     width: 148px;
//     height: 118px;
//   }
// }
.image-view.form-upload-item {
  margin-top: 10px;
  margin-left: 10px;
  width: 118px;
  height: 148px;
  &.horizontal {
    width: 148px;
    height: 118px;
  }
}
.checkbox-form {
  position: absolute;
  right: 8px;
  bottom: 18px;
  width: 14px;
  height: 14px;
  margin-bottom: 0;
}
.upload-item-select {
  
}
.date-range {
  color: #8D8D8D;
  padding-left: 100px;
  margin-top: -15px;
}
</style>