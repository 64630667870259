<template>
  <!-- 列表 -->
  <a-table
    class="table-view"
    :data-source="dataSource"
    :columns="columns"
    :row-key="record => record.id"
    :loading="isLoading"
    :pagination="false"
  >
    <!-- 自定义行内容 -->
    <template #bodyCell="{ column, record, index }">
      <!-- 操作 -->
      <template v-if="column.key === 'operation'">
        <!-- 设置 -->
        <a-button
          type="link"
          @click="handleEdit(record)"
        >
          编辑
        </a-button>
      </template>
    </template>
  </a-table>
  <!-- 抖小 - 联系客服 -->
  <TtServiceInfo ref="RefTtServiceInfo" @success="getData"></TtServiceInfo>
  <!-- 抖小 - 加入粉丝群 -->
  <TtGroupInfo ref="RefTtGroupInfo" @success="getData"></TtGroupInfo>
  <!-- 微小 - 联系客服 -->
  <WxContactService ref="RefWxContactService" @success="getData"></WxContactService>
  <!-- 微小 - 首页关注引导 -->
  <WxHomeGuide ref="RefWxHomeGuide" @success="getData"></WxHomeGuide>
  <!-- 微小 - 播放页关注引导 -->
  <WxPlayGuide ref="RefWxPlayGuide" @success="getData"></WxPlayGuide>
  <!-- 微小 - 支付成功 -->
  <WxPayGuide ref="RefWxPayGuide" @success="getData"></WxPayGuide>
  <!-- 快小 - 关注服务号 -->
  <KsFollowInfo ref="RefKsFollowInfo" @success="getData"></KsFollowInfo>
  <!-- 公用 - 关注服务号 -->
  <PubFollowInfo ref="RefPubFollowInfo" @success="getData"></PubFollowInfo>
  <!-- 公用 - 播放页 - 关闭付费面板 -->
  <PubAdUnlockDrawer ref="RefPubAdUnlockDrawer" @success="getData"></PubAdUnlockDrawer>
  <!-- 公用 - 福利 - 免费看剧模块 -->
  <PubFreeDramasDrawer ref="RefPubFreeDramasDrawer" @success="getData"></PubFreeDramasDrawer>
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import { message } from 'ant-design-vue'
import TtGroupInfo from './TtGroupInfo.vue'
import TtServiceInfo from './TtServiceInfo.vue'
import WxContactService from './WxContactService.vue'
import WxHomeGuide from './WxHomeGuide.vue'
import WxPlayGuide from './WxPlayGuide.vue'
import WxPayGuide from './WxPayGuide.vue'
import KsFollowInfo from './KsFollowInfo.vue'
import PubFollowInfo from './PubFollowInfo.vue'
import PubAdUnlockDrawer from './PubAdUnlockDrawer.vue'
import PubFreeDramasDrawer from './PubFreeDramasDrawer.vue'
import { TTGuidance, KSGuidance, WXGuidance } from '@/utils/constantList'

// props
const props = defineProps({
  platform: {
    type: Number,
    default: undefined
  }
})
// 抖小 - 加入粉丝群
let RefTtGroupInfo = ref(null)
// 抖小 - 联系客服
let RefTtServiceInfo = ref(null)
// 微小 - 联系客服
let RefWxContactService = ref(null)
// 微小 - 首页关注引导
let RefWxHomeGuide = ref(null)
// 微小 - 播放页关注引导
let RefWxPlayGuide = ref(null)
// 微小 - 支付成功
let RefWxPayGuide = ref(null)
// 快小 - 关注抖音号
let RefKsFollowInfo = ref(null)
// 公用 - 关注抖音号
let RefPubFollowInfo = ref(null)
// 公用 - 播放页 - 关闭付费面板
let RefPubAdUnlockDrawer = ref(null)
// 公用 - 福利 - 免费看剧模块
let RefPubFreeDramasDrawer = ref(null)
// 加载
let isLoading = ref(false)
// 数据源
let dataSource = ref([])
// 列数据源
let columns = ref([
  {
    title: '配置项',
    width:'65%',
    dataIndex: 'text',
    key: 'text'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation'
  }
])

// 钩子函数
onBeforeMount(() => {
  // 列表
  if (props.platform === 1) {
    dataSource.value = TTGuidance
  } else if (props.platform === 2) {
    dataSource.value = KSGuidance
  } else if (props.platform === 3) {
    dataSource.value = WXGuidance
  }
})

// 编辑
function handleEdit (record) {
  if (props.platform === 1) {
    // 抖音
    if (record.value == 1) {
      // 关注抖音号
      RefPubFollowInfo.value.showDrawer(props.platform, record.value, '抖小 - 关注抖音号')
    } else if (record.value == 2) {
      // 加入粉丝群
      RefTtGroupInfo.value.showDrawer(props.platform, record.value)
    } else if (record.value == 3) {
      // 联系客服
      RefTtServiceInfo.value.showDrawer(props.platform, record.value)
    } else if (record.value == 4) {
      // 播放页 - 关闭付费面板
      RefPubAdUnlockDrawer.value.showDrawer(props.platform, record.value, '抖小 - 播放页 - 关闭付费面板')
    } else if (record.value == 20) {
      // 福利 - 免费看剧模块
      RefPubFreeDramasDrawer.value.showDrawer(props.platform, record.value, '抖小 - 福利 - 免费看剧模块')
    }
  } else if (props.platform === 2) {
    // 快手
    if (record.value == 1) {
      // 关注服务号
      RefKsFollowInfo.value.showDrawer(props.platform, record.value, '快小 - 关注服务号')
    } else if (record.value == 2) {
      // 播放页 - 关闭付费面板
      RefPubAdUnlockDrawer.value.showDrawer(props.platform, record.value, '快小 - 播放页 - 关闭付费面板')
    } else if (record.value == 20) {
      // 福利 - 免费看剧模块
      RefPubFreeDramasDrawer.value.showDrawer(props.platform, record.value, '快小 - 福利 - 免费看剧模块')
    }
  } else if (props.platform === 3) {
    // 微信
    if (record.value == 1) {
      // 首页-关注引导
      RefWxHomeGuide.value.showDrawer(props.platform, record.value)
    } else if (record.value == 2) {
      // 播放页-关注引导
      RefWxPlayGuide.value.showDrawer(props.platform, record.value)
    } else if (record.value == 3) {
      // 付费成功
      RefWxPayGuide.value.showDrawer(props.platform, record.value)
    } else if (record.value == 4) {
      // 联系客服
      RefWxContactService.value.showDrawer(props.platform, record.value)
    } else if (record.value == 5) {
      // 播放页 - 关闭付费面板
      RefPubAdUnlockDrawer.value.showDrawer(props.platform, record.value, '微小 - 播放页 - 关闭付费面板')
    } else if (record.value == 20) {
      // 福利 - 免费看剧模块
      RefPubFreeDramasDrawer.value.showDrawer(props.platform, record.value, '微小 - 福利 - 免费看剧模块')
    }
  }
}

</script>

<style lang="less" scoped>
.table-view {
  margin-top: 10px;
  :deep(.ant-table-cell) {
    vertical-align: top;
  }
  .play-name {
    &:not(:last-child) {
      margin-bottom: 28px;
    }
    .gap {
      margin: 0 8px;
    }
  }
}
a[disabled] {
  pointer-events: none;
  cursor: default;
}
.tag-type {
  margin-left: 20px;
  background: #eee;
}
</style>