<template>
  <!-- 面包屑 -->
  <a-breadcrumb>
    <a-breadcrumb-item>前台配置</a-breadcrumb-item>
    <a-breadcrumb-item>引导配置</a-breadcrumb-item>
  </a-breadcrumb>
  <a-tabs class="tab-box" v-model:activeKey="activeKey">
    <a-tab-pane v-for="item in Platforms" :key="item.value" :tab="item.text">
      <!-- 每个平台对应的模板 -->
      <PlatformTemplate
        :platform="item.value"
        v-if="activeKey === item.value"
      />
    </a-tab-pane>
  </a-tabs>
</template>

<script setup>
import { ref, reactive } from 'vue'
// import { Platforms } from '@/utils/constantList'
import PlatformTemplate from './components-guide/PlatformTemplate'

// tabkey
let activeKey = ref(1)
// h5平台任务模块暂时隐藏
let Platforms = [
  {
    value: 1,
    text: '抖音'
  },
  {
    value: 2,
    text: '快手'
  },
  {
    value: 3,
    text: '微信'
  }
]

</script>

<style lang="less" scoped>

</style>