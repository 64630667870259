<template>
  <!-- 剧集抽屉 -->
  <a-drawer
    title="抖小 - 加入粉丝群"
    width="700"
    :closable="false"
    :visible="visible"
    @close="onClose"
    :footer-style="{ textAlign: 'right' }"
  >
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 16 }"
    >
      <!-- 粉丝群ID -->
      <a-form-item
        label="粉丝群ID"
        name="num"
        :rules="[{ required: true, validator: validatorOther }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.num"
        />
      </a-form-item>
      <!-- 引导开关 -->
      <a-form-item
        label="引导开关"
        name="ison_home"
      >
        <a-switch v-model:checked="formState.ison_home" />
      </a-form-item>
      <!-- icon -->
      <a-form-item
        label="icon"
        name="cover_home"
        :rules="[{ required: formState.ison_home, validator: validator }]"
      >
        <!-- 上传组件 -->
        <a-form-item-rest>
          <Upload
            accept=".png,.jpg,.gif"
            uploadSource="GroupInfo"
            :imgSizeMode="3"
            :imgSizeWidth="300"
            :imgSizeHeight="300"
            imgSizeError="图片尺寸为 300 * 300"
            :beforeUploadPro="beforeUploadPro"
            :uploadResult="(fileJson, res, record) => uploadResult('cover_home', fileJson, res, record)"
            :fileList="formState.cover_home"
            :uploadBucket="$pub.BUCKET_PUB()"
          />
        </a-form-item-rest>
        <div class="form-upload-hint">支持.png .jpg .gif，尺寸 300x300</div>
        <div class="form-upload-list" v-if="formState.cover_home.length">
          <ImageView
            class="form-upload-item"
            v-for="(item, index) in formState.cover_home"
            :key="index"
            :url="$pub.CDN_URL(item.url)"
            :fileJson="item"
            @delete="touchDeleteCover('cover_home', index)"
          >
          </ImageView>
        </div>
      </a-form-item>
      <!-- 付费成功引导 -->
      <a-form-item
        label="付费成功引导"
        name="ison_pay"
      >
        <a-switch v-model:checked="formState.ison_pay" />
      </a-form-item>
      <!-- 引导图片 -->
      <a-form-item
        label="引导图片"
        name="cover_pay"
        :rules="[{ required: formState.ison_pay, validator: validator }]"
      >
        <!-- 上传组件 -->
        <a-form-item-rest>
          <Upload
            accept=".png,.jpg,.gif"
            uploadSource="GroupInfo"
            :imgSizeMode="3"
            :imgSizeWidth="630"
            :imgSizeHeight="730"
            imgSizeError="图片尺寸为 630 * 730"
            :beforeUploadPro="beforeUploadPro"
            :uploadResult="(fileJson, res, record) => uploadResult('cover_pay', fileJson, res, record)"
            :fileList="formState.cover_pay"
            :uploadBucket="$pub.BUCKET_PUB()"
          />
        </a-form-item-rest>
        <div class="form-upload-hint">支持.png .jpg .gif，尺寸 630x730</div>
        <div class="form-upload-list" v-if="formState.cover_pay.length">
          <ImageView
            class="form-upload-item"
            v-for="(item, index) in formState.cover_pay"
            :key="index"
            :url="$pub.CDN_URL(item.url)"
            :fileJson="item"
            @delete="touchDeleteCover('cover_pay', index)"
          >
          </ImageView>
        </div>
      </a-form-item>
    </a-form>
    <!-- 尾部 -->
    <template #footer>
      <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
      <a-button type="primary" @click="touchSubmit">确定</a-button>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
    <!-- 预览 -->
    <PreviewModal ref="RefPreviewModal"></PreviewModal>
  </a-drawer>
</template>

<script setup>
import { ref, reactive, nextTick } from 'vue'
import { message } from 'ant-design-vue'
import Loading from '@/components/Loading'
import Upload from '@/components/Upload'
import ImageView from '@/components/ImageView'
import PreviewModal from '@/components/preview/PreviewModal'
import Pub from '@/utils/public'
import { getLeadConfig, leadConfigSave } from '@/api/operate'
// 引入 dayjs 及 扩展函数
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
dayjs.extend(isBetween)

const emit = defineEmits('success')
// Form实例
const RefForm = ref(null)
// 视频预览
const RefPreviewModal = ref(null)
// 抽屉展示状态
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 平台ID
let platformID  = ref(undefined)
// 详情ID
let id = ref(undefined)
// 信息
let formState = reactive({
  // 引导开关
  ison_home: false,
  // 支付完成引导(播放页)
  ison_pay: false,
  // 群ID
  num: undefined,
  // 引导开关图
  cover_home: [],
  // 支付成功引导图
  cover_pay: [],
})

// 打开抽屉
function showDrawer (pid, did) {
  visible.value = true
  id.value = did
  platformID.value = pid
  // 获取详情
  getDetail()
}

// 获取详情
function getDetail () {
  isLoading.value = true
  getLeadConfig({
    platform_id: platformID.value,
    type: id.value
  }).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      // 设置表单显示
      nextTick(() => {
        formState.num = data.num
        formState.ison_home = data.ison_home ? true : false
        formState.ison_pay = data.ison_pay ? true : false
        formState.cover_home = data.cover_home && [data.cover_home] || []
        formState.cover_pay = data.cover_pay && [data.cover_pay] || []
      })
    } else {
      message.error(res.message || msg)
    }
  })
}

// 关闭抽屉
function onClose () {
  if (!isLoading.value) {
    RefForm.value.resetFields()
    visible.value = false
  }
}

// 准备上传（图片）
function beforeUploadPro (file) {
  if (!file.type.includes('image')) {
    message.error('请上传图片类型的文件')
    return false
  }
  return true
}

// 上传结果
function uploadResult (key, fileJson, res, record) {
  // 上传结果判断
  if (fileJson.status === 'done') {
    RefForm.value.validate()
    formState[key] = [fileJson]
  }
}

// 校验ID
function validatorOther (rule, value) {
  if (!value) {
    return Promise.reject('请输入')
  }
  // else if (!Pub.REG_IS_INTEGER(value)) {
  //   return Promise.reject('必须为纯数字')
  // }
  return Promise.resolve()
}

// 图片校验
function validator (rule, value) {
  // 图片
  if (rule.field === 'cover_home') {
    if (formState.ison_home) {
      if (value && value.length === 0) {
        return Promise.reject('请上传')
      }
    }
    return Promise.resolve()
  }
  // 图片
  if (rule.field === 'cover_pay') {
    if (formState.ison_pay) {
      if (value && value.length === 0) {
        return Promise.reject('请上传')
      }
    }
    return Promise.resolve()
  }
  // 都通过
  return Promise.resolve()
}

// 移除封面
function touchDeleteCover (coverKey, index) {
  formState[coverKey].splice(index, 1)
}

// 提交
function touchSubmit () {
  RefForm.value.validate().then((() => {
    // 检查上传
    const imgs = (formState.cover_home || []).concat(formState.cover_pay || [])
    const isUploadSuccess = imgs.every(item => {
      return item.status === undefined || item.status === 'done'
    })
    if (!isUploadSuccess) {
      message.error('正在上传中...')
      return
    }
    isLoading.value = true
    // 头像信息
    const cover_home = formState.cover_home[0]
    const cover_pay = formState.cover_pay[0]
    // 准备数据
    const params = {
      platform_id: platformID.value,
      type: id.value,
      ...formState,
      cover_home,
      cover_pay,
      ison_home: formState.ison_home ? 1 : 0,
      ison_pay: formState.ison_pay ? 1 : 0
    }
    leadConfigSave(params).then(res => {
      isLoading.value = false
      const { code, msg, data } = res
      if (code === 0) {
        message.success('操作成功')
        onClose()
        emit('success')
      } else {
        message.error(res.message || msg)
      }
    })
  }))
}

// 暴露出去
defineExpose({
  showDrawer
})

</script>

<style lang="less" scoped>
.a-link {
  margin-left: 8px;
}
.form-item-clear {
  margin-bottom: 0;
}
.form-upload-hint {
  padding-top: 6px;
  color: #8D8D8D;
}
.form-upload-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
}
// .form-upload-item {
//   margin-top: 10px;
//   margin-left: 10px;
//   position: relative;
//   width: 118px;
//   height: 148px;
//   border-radius: 4px;
//   overflow: hidden;
//   img {
//     display: inline-block;
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//   }
//   &.horizontal {
//     width: 148px;
//     height: 118px;
//   }
// }
.image-view.form-upload-item {
  margin-top: 10px;
  margin-left: 10px;
  width: 118px;
  height: 148px;
  &.horizontal {
    width: 148px;
    height: 118px;
  }
}
.checkbox-form {
  position: absolute;
  right: 8px;
  bottom: 18px;
  width: 14px;
  height: 14px;
  margin-bottom: 0;
}
.upload-item-select {
  
}
.date-range {
  color: #8D8D8D;
  padding-left: 100px;
  margin-top: -15px;
}
</style>